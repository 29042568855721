$(document).ready(function() {
        if ($('#left-navbar-id').length) {
            var sidebar = new StickySidebar('#left-navbar-id', {topSpacing: 80, minWidth: 1600});
        }

        fixniNav();
        $('.partners-slick').slick({
            slidesToShow: 8,
            autoplay: true,
            infinite: false,
            variableWidth: false,
            autoplaySpeed: 2000,
            responsive: [
            {
                breakpoint: 968,
                settings: {
                    centerMode: false,
                    infinite: true,
                    variableWidth: true,
                    arrows: true,
                    slidesToShow: 7
                }
            },
            {
                breakpoint: 768,
                settings: {
                    centerMode: false,
                    infinite: true,
                    variableWidth: true,
                    slidesToShow: 6
                }
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: false,
                    infinite: true,
                    variableWidth: true,
                    slidesToShow: 3
                }
            }]
        });

        $('.reviews').slick({
            slidesToShow: 1,
            autoplay: true,
            infinite: true,
            adaptiveHeight: true,
            autoplaySpeed: 10000
        });

    $(window).scroll(function() {fixniNav();});
    $(window).resize(function() {fixniNav();});
    $(".parallax").paroller();
    function fixniNav() {

        var navigace = $('#information-header-part');
            headerTop = $('#header-top');
            rNav = $("#rHeader-top");
            var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;


        if(w > 620) {
            if( $(this).scrollTop() > headerTop.height()) {
                navigace.addClass("sticky");
            } else {
                navigace.removeClass("sticky");
            }
        } else {
            if( $(this).scrollTop() > $("#rHeader-top .logo-brand").height()) {
                rNav.addClass("sticky");
            } else {
                rNav.removeClass("sticky");
            }
        }
    }

    function initMap() {
      // The location of Uluru
      var uluru = {lat: -25.344, lng: 131.036};
      // The map, centered at Uluru
      var map = new google.maps.Map(
          document.getElementById('map'), {zoom: 4, center: uluru});
      // The marker, positioned at Uluru
      var marker = new google.maps.Marker({position: uluru, map: map});
    }

});